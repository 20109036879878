import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
// import './assets/styles/index.css';
import './assets/scss/main.scss'; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import ClientRoute from './components/Route/ClientRoute';
import NotificationScreen from './screens/NotificationScreen';
import OrganizationLoginScreen from './screens/organization/OrganizationLoginScreen';
import OrganizationProfileScreen from './screens/organization/OrganizationProfileScreen';
import OrganizationMembersScreen from './screens/organization/OrganizationMembersScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/login' element={<OrganizationLoginScreen />} />
      <Route path='/notifications' element={<NotificationScreen />} />

      <Route  path='' element={<ClientRoute />}>
        <Route  path='/profile' element={<OrganizationProfileScreen />} />
        <Route  path='/' element={<OrganizationMembersScreen />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();