import { apiSlice } from './apiSlice';
import { NOTIFICATIONS_URL } from '../constants/constants';

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: ({ role }) => {
                let url = `${NOTIFICATIONS_URL}`;
                if (role === 'user') {
                    url = `${NOTIFICATIONS_URL}/user`;
                } else if (role === 'client') {
                    url = `${NOTIFICATIONS_URL}/organization`;
                } else {
                    url = `${NOTIFICATIONS_URL}/shop`;
                }
                return {
                    url,
                    method: 'GET',
                };
            },
            keepUnusedDataFor: 5,
        }),
        getUnreadNotifications: builder.query({
            query: ({ role }) => {
                let url = `${NOTIFICATIONS_URL}/unread`;
                if (role === 'user') {
                    url = `${NOTIFICATIONS_URL}/user/unread`;
                } else if (role === 'client') {
                    url = `${NOTIFICATIONS_URL}/organization/unread`;
                } else {
                    url = `${NOTIFICATIONS_URL}/shop/unread`;
                }
                return {
                    url,
                    method: 'GET',
                };
            },
            keepUnusedDataFor: 5,
        }),
        markNotificationsAsRead: builder.mutation({
            query: ({ role }) => {
                let url = `${NOTIFICATIONS_URL}/read`;
                if (role === 'user') {
                    url = `${NOTIFICATIONS_URL}/user/read`;
                } else if (role === 'client') {
                    url = `${NOTIFICATIONS_URL}/organization/read`;
                } else {
                    url = `${NOTIFICATIONS_URL}/shop/read`;
                }
                return {
                    url,
                    method: 'PUT',
                };
            },
        }),
    }),
});

export const { 
    useGetNotificationsQuery,
    useGetUnreadNotificationsQuery,
    useMarkNotificationsAsReadMutation,
} = notificationsApiSlice;
