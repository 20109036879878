import { useState, useEffect, useCallback } from 'react';
import { Table, Form, Button, Row, Col, ListGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import PasswordModal from '../../components/PasswordModal';
import {
    useVerifyCurrentOrganizationPasswordMutation,
    useGetMyOrganizationQuery,
    useUpdateOrganizationProfileMutation,
} from '../../slices/organizationsApiSlice';
import { setCredentials } from '../../slices/authSlice';

const OrganizationProfileScreen = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);

    const [verifyCurrentPassword, { isLoading: loadingVerifyPassword }] = useVerifyCurrentOrganizationPasswordMutation();
    const [updateProfile, { isLoading: loadingUpdateProfile }] = useUpdateOrganizationProfileMutation();

    const { data: organization, isLoading, error, refetch } = useGetMyOrganizationQuery();

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
        }
    }, [userInfo, userInfo.name, userInfo.email]);

    const [organizationData, setOrganizationData] = useState({
        representative: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
        },
        phoneNumber: '',
    });

    useEffect(() => {
        if (organization) {
            setOrganizationData({
                representative: organization.representative,
                address: {
                    street: organization.address.street,
                    city: organization.address.city,
                    state: organization.address.state,
                    zipCode: organization.address.zipCode,
                },
                phoneNumber: organization.phoneNumber,
            });
        }
    }, [organization]);

    const handleChange = useCallback(({ target: { name, value, dataset } }) => {
        setOrganizationData((prev) => {
            if (dataset?.group) {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        [name]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [name]: value,
                };
            }
        });
    }, []);

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setShowEditModal(false);
        if (organization) {
            setOrganizationData((prev) => ({
                ...prev,
                representative: organization.representative,
                address: {
                    street: organization.address.street,
                    city: organization.address.city,
                    state: organization.address.state,
                    zipCode: organization.address.zipCode,
                },
                phoneNumber: organization.phoneNumber,
            }));
        }
    }, [organization]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await updateProfile({
                name,
                email,
                ...organizationData,
            }).unwrap();
            dispatch(setCredentials(res));
            toast.success('プロフィールが更新されました');
            refetch();
            setIsEditing(false);
            setShowEditModal(false);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const handleSubmitPassword = async (currentPassword, newPassword) => {
        if (currentPassword) {
            await verifyCurrentPassword({ password: currentPassword }).unwrap();
        } else if (newPassword) {
            const res = await updateProfile({ password: newPassword }).unwrap();
            dispatch(setCredentials(res));
        }
    };

    return (
        <Row>
            {isLoading ? (
                <h2>読み込み中...</h2>
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <>
                    <Col md={4}>
                        <h2>プロフィール</h2>
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name' className='my-2 me-5'>
                                <Form.Label>名前</Form.Label>
                                <Form.Control
                                    type='name'
                                    placeholder='名前を入力'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='email' className='my-2 me-5'>
                                <Form.Label>メールアドレス</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='メールアドレスを入力'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                ></Form.Control>
                            </Form.Group>

                            <Button type='submit' variant='primary' className='my-2'>
                                更新
                            </Button>
                            {loadingUpdateProfile && <Loader />}
                        </Form>

                        <h2 className='mt-4'>パスワード</h2>
                        <Button
                            variant="primary"
                            className="my-2"
                            onClick={() => setShowPasswordModal(true)}
                        >
                            変更する
                        </Button>
                    </Col>
                    <Col md={4}>
                        <h2>組織情報</h2>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='me-5'>
                                <Row className='px-2'>
                                    <Col md={3}>
                                        <strong>代表者名:</strong>
                                    </Col>
                                    <Col className='text-end'> {organization.representative} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='me-5'>
                                <Row className='px-2'>
                                    <Col md={3}>
                                        <strong>住所:</strong>
                                    </Col>
                                    <Col className='text-end'>
                                        〒{organizationData.address.zipCode} <br />
                                        {organizationData.address.state} {organizationData.address.city}{' '}
                                        {organizationData.address.street}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='me-5'>
                                <Row className='px-2'>
                                    <Col md={3}>
                                        <strong>電話番号:</strong>
                                    </Col>
                                    <Col className='text-end'> {organization.phoneNumber} </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button variant='primary' className='my-2' onClick={() => setShowEditModal(true)}>
                                    編集
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </>
            )}

            {/* 編集用モーダル */}
            <Modal show={showEditModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>組織編集</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId='representative' className='my-2 me-5'>
                            <Form.Label>代表者名</Form.Label>
                            <Form.Control
                                type='text'
                                name='representative'
                                value={organizationData.representative}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId='addressZipCode' className='my-2 me-5'>
                            <Form.Label>住所：郵便番号</Form.Label>
                            <Form.Control
                                type='text'
                                name='zipCode'
                                placeholder='郵便番号を入力'
                                value={organizationData.address.zipCode}
                                onChange={handleChange}
                                data-group='address'
                            />
                        </Form.Group>
                        <Form.Group controlId='addressState' className='my-2 me-5'>
                            <Form.Label>住所：都道府県</Form.Label>
                            <Form.Control
                                type='text'
                                name='state'
                                placeholder='都道府県を入力'
                                value={organizationData.address.state}
                                onChange={handleChange}
                                data-group='address'
                            />
                        </Form.Group>
                        <Form.Group controlId='addressCity' className='my-2 me-5'>
                            <Form.Label>住所：市区町村</Form.Label>
                            <Form.Control
                                type='text'
                                name='city'
                                placeholder='市区町村を入力'
                                value={organizationData.address.city}
                                onChange={handleChange}
                                data-group='address'
                            />
                        </Form.Group>
                        <Form.Group controlId='addressStreet' className='my-2 me-5'>
                            <Form.Label>住所：番地、部屋番号など</Form.Label>
                            <Form.Control
                                type='text'
                                name='street'
                                placeholder='番地、部屋番号などを入力'
                                value={organizationData.address.street}
                                onChange={handleChange}
                                data-group='address'
                            />
                        </Form.Group>
                        <Form.Group controlId='phoneNumber' className='my-2 me-5'>
                            <Form.Label>電話番号</Form.Label>
                            <Form.Control
                                type='text'
                                name='phoneNumber'
                                value={organizationData.phoneNumber}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button type='submit' variant='primary' className='my-2'>
                            更新
                        </Button>
                        <Button variant='light' className='my-2 mx-2' onClick={handleCancel}>
                            キャンセル
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <PasswordModal 
                show={showPasswordModal}
                handleClose={() => setShowPasswordModal(false)}
                handleSubmitPassword={handleSubmitPassword}
                loadingVerifyPassword={loadingVerifyPassword}
                loadingUpdateProfile={loadingUpdateProfile}
            />
        </Row>
    );
};

export default OrganizationProfileScreen;
