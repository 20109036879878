import { useState, useEffect } from 'react';
import { Table, Button, Row, Col, ListGroup, Modal, Form, InputGroup, FormControl, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaSync } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
    useGetOrganizationMembersQuery,
    useAddOrganizationMemberMutation,
    useRemoveOrganizationMemberMutation,
    useHandleApprovalRequestMutation,
} from '../../slices/organizationsApiSlice';
import { formatDate } from '../../utils/dateUtils';

const OrganizationMembersScreen = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.auth);

    const { data, isLoading, error, refetch } = useGetOrganizationMembersQuery();
    const [addMember, { isLoading: loadingAddMember }] = useAddOrganizationMemberMutation();
    const [removeMember, { isLoading: loadingRemoveMember }] = useRemoveOrganizationMemberMutation();
    const [handleApprovalRequest, { isLoading: loadingHandleApproval }] = useHandleApprovalRequestMutation();

    const [email, setEmail] = useState('');
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const openApprovalModal = (request) => {
        setSelectedRequest(request);
        setShowApprovalModal(true);
    };

    const closeApprovalModal = () => {
        setSelectedRequest(null);
        setShowApprovalModal(false);
    };

    const handleApproval = async (action) => {
        try {
            await handleApprovalRequest({ userId: selectedRequest, action }).unwrap();
            toast.success(`承認リクエストが${action === 'approve' ? '承認' : '拒否'}されました`);
            refetch();
            closeApprovalModal();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const addMemberHandler = async (e) => {
        e.preventDefault();
        try {
            await addMember({ email }).unwrap();
            toast.success('メンバーが追加されました');
            setEmail('');
            refetch();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const removeMemberHandler = async (userId) => {
        if (window.confirm('このメンバーを削除しますか？')) {
            try {
                await removeMember({ userId }).unwrap();
                toast.success('メンバーが削除されました');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => toast.success('URLがクリップボードにコピーされました'))
            .catch(() => toast.error('URLのコピーに失敗しました'));
    };

    const generateApprovalRequestUrl = () => {
        const baseUrl = window.location.origin;
        return `${baseUrl}/user-approval-request/${userInfo.id}`;
    };

    return (
        <Container>
            <Row>
                <Col md={6}>
                    <Row>
                        <h2>メンバー管理</h2>
                        {isLoading ? (
                            <Loader />
                        ) : error ? (
                            <Message variant='danger'>{error?.data?.message || error.error}</Message>
                        ) : (
                            data?.members && (
                                <ListGroup variant='flush'>
                                    {data.members.map((member) => (
                                        <ListGroup.Item key={member.id}>
                                            <Row>
                                                <Col>{member.name}</Col>
                                                <Col>{member.email}</Col>
                                                <Col>
                                                    <Button
                                                        variant='danger'
                                                        className='btn-sm'
                                                        onClick={() => removeMemberHandler(member.id)}
                                                    >
                                                        削除
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )
                        )}
                    </Row>
                    <Row className='mt-4'>
                        <h2>リンク</h2>
                        <p>
                            以下のリンクをコピーして、メンバーに送信してください。メンバーはリンクから承認リクエストを送信することができます。
                        </p>
                        <InputGroup className='my-2'>
                            <FormControl
                                type='text'
                                readOnly
                                value={generateApprovalRequestUrl()}
                            />
                            <Button variant='secondary' onClick={() => copyToClipboard(generateApprovalRequestUrl())}>
                                コピー
                            </Button>
                        </InputGroup>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <h2>メンバー追加</h2>
                        <Form onSubmit={addMemberHandler}>
                            <Form.Group controlId='email' className='my-2'>
                                <Form.Label>メールアドレス</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='メンバーのメールアドレスを入力'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                            <Button type='submit' variant='primary' className='my-2' disabled={loadingAddMember}>
                                追加
                            </Button>
                            {loadingAddMember && <Loader />}
                        </Form>
                    </Row>
                    <Row className='mt-4'>
                        <Row className="d-flex align-items-center mt-4 mb-3">
                            <Col>
                                <h2>承認リクエスト一覧</h2>
                            </Col>
                            <Col>
                                <Button
                                    variant="outline-primary"
                                    className="ms-3 d-flex align-items-center"
                                    onClick={() => refetch()}
                                    disabled={isLoading}
                                >
                                    <FaSync className={`me-2 ${isLoading ? 'fa-spin' : ''}`} />
                                    リフレッシュ
                                </Button>
                            </Col>
                        </Row>
                        {isLoading ? (
                            <Loader />
                        ) : error ? (
                            <Message variant='danger'>{error?.data?.message || error.error}</Message>
                        ) : (
                            data?.approvalRequests && (
                                <ListGroup variant='flush'>
                                    {data.approvalRequests.map((request) => (
                                        <ListGroup.Item key={request.User.id}>
                                            <Row>
                                                <Col>{request.User.name}</Col>
                                                <Col>{request.User.email}</Col>
                                                <Col>
                                                    <Button
                                                        variant='primary'
                                                        className='btn-sm'
                                                        onClick={() => openApprovalModal(request.User.id)}
                                                    >
                                                        承認/拒否
                                                    </Button>
                                                </Col>
                                                <Col>{formatDate(request.createdAt)}</Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )
                        )}
                    </Row>
                </Col>

                <Modal show={showApprovalModal} onHide={closeApprovalModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>承認リクエストの処理</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>このリクエストを承認または拒否しますか？</p>
                        <Button variant='success' onClick={() => handleApproval('approve')} className='me-3'>
                            承認
                        </Button>
                        <Button variant='danger' onClick={() => handleApproval('reject')}>
                            拒否
                        </Button>
                        {loadingHandleApproval && <Loader />}
                    </Modal.Body>
                </Modal>
            </Row>
        </Container>
    );
};

export default OrganizationMembersScreen;
